module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Xhain beruhigt sich - Verkehrsberuhigung im Bezirk Friedrichshain-Kreuzberg","short_name":"Xhain beruhigt sich","start_url":"/","background_color":"white","theme_color":"#fff","icon":"src/images/xhain-icon.png","display":"minimal-ui","legacy":false,"lang":"de-DE","theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"46b3e43f1d8ef7cf13f47742d45281d4"},
    },{
      plugin: require('../node_modules/gatsby-source-remote-csv/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://docs.google.com/spreadsheets/d/1bcnGGYrkUL6_2JV3blFl5apKZOr4P6TcFnzyLaHjX-0/export?format=csv&id=1bcnGGYrkUL6_2JV3blFl5apKZOr4P6TcFnzyLaHjX-0&gid=1543884618"},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
